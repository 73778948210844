.cookie-permission-holder{
  display: none;
  position: fixed;
  z-index: 999;
  bottom: .83vw;
  left: .83vw;
  min-height: 5.55vw;
  width: 25vw;
  padding: 1.11vw;
  border-radius: 10px;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.25);
  background-color: #FFF;
  @media(max-width: 1023px) {
    width: 90%;
    left: 5%;
    bottom: 8px;
    min-height: 56px;
    padding: 12px;
  }
  & p{
    font-size: .97vw;
    line-height: 1.71;
    color: #111418;
    margin: 0;
    @media(max-width: 1023px) {
      font-size: 12px;
    }
  }
  & a{
    color: #2979ff;
  }
  &__close{
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    width: 1.2vw;
    @media(max-width: 1023px) {
      width: 16px;
    }
  }
}